.ui.dimmer {
    background-color: rgba(0,0,0,.3) !important;
}

#header-1 {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 35px;
}

#header-2 {
    margin-top: 0px;
    font-size: 14px;
}

#my-container {
    height: 100vh;
    width: 100%;
}

#submit-button {
    background: white;
    color: #D82E2E;
}

#envelope-segment {
    height: 640px;
    width: 310px;
    background-color: #D82E2E !important;
    border-radius: 20px;
}

#envelope-col {
    margin-left: -20px;
    width: 310px;
}

#envelope-form {
    margin-top: 50px;
    margin-bottom: 90px;
}

#envelope-image {
    max-width: none;
    margin-top: -15px;
    margin-left: -15px;
    width: 310px;
}
#congrats-emoji {
    font-size: 90px;
}

#congrats-title {
    font-size: 30px;
}

#congrats-content {
    font-size: 50px;
    font-weight: 700;
}

#congrats-footer {
    font-size: 25px;
}